@mixin theme(
	$color-text,
	$color-header-text,
	$color-background,
	$color-header-background,
	$color-footer,
	$color-inverted-background,
	$color-inverted-text,
	$color-primary,
	$color-secondary,
	$color-accent,
	$color-line) {

	//////// global
	color: $color-text;

	.inverted {
	    background: $color-inverted-background;
	    color: $color-inverted-text;
	}


	/////// header
	.page-header {
		color: $color-header-text;
		background: $color-background;
	    border-bottom: $width-gap/4 solid $color-accent;
	    box-shadow: 0px 0.5rem 1rem -0.75rem $color-accent;
	}

	.nav__primary {

		a:hover { background: darken($color-background, 5%); }
		li + li { border-left: 0.125rem solid $color-accent; }
	}

	.nav__mobile {
		border-top: 0.125rem solid $color-accent;

		li + li { border-top: 0.125rem solid darken($color-primary, 5%); }
		a:hover { background: darken($color-primary, 5%); }
	}

	.cofe__bar {
		background: $color-secondary;
	    color: #fff;

		a + a { border-left: 1px solid lighten($color-secondary, 20%); }

		a:hover {
	        background: #fff;
	        color: $color-text;
	    }

	    .nav__mobile & {
			border-top: 0.25rem solid darken($color-secondary, 5%);

		 	a + a { border-top: 1px solid lighten($color-secondary, 20%); }
	 	}
	}

	/////// footer

	.page-footer {
		background: $color-footer;
		color: $color-text;
		border-bottom: $width-gap/2 solid darken($color-footer, 2.5%);
	    box-shadow: 0px 100vh 0px 100vh darken($color-footer, 2.5%);

	}


	/////// content
	.main__header {
		background: $color-header-background;

		&.with-bg-img:after {
			background: rgba(#fff, 0.925);
		}

	    @media all and (max-width: 767px) {
	        border-top: 0.5rem solid $color-secondary;
	    }
	}


	//////// elements

	.btn {
		&.primary {
	        background: $color-primary;
	        border-bottom: 2px solid darken($color-primary, 10%);
	    }
	    &.secondary {
	        background: $color-secondary;
	        border-bottom: 2px solid darken($color-secondary, 10%);
	    }
	}


	//////// forms

	main form {
		input:not([type=checkbox]):not([type=radio]):not([type=submit]),
	    textarea,
	    select  {
			border: $width-line solid $color-line;
		}
		.errorlist {
			color: $color-error;
            margin-bottom: 0;

            + .field-error {
                margin-top: 0;
            }
		 }

         .field-error {
            label { color: $color-error; }
            input:not([type=checkbox]):not([type=radio]):not([type=submit]),
            textarea,
            select  {
                border-color: $color-error;
            }
         }
	}
}
