.button,
.btn {
    display: inline-block;
    padding: $width-gap / 2;
    user-select: none;
    cursor: pointer;

    &.primary { color: #fff; }
    &.secondary { color: #fff; }
    &.error {
        background: #cc0000;
        color: #fff;
        border-bottom: 2px solid darken(#cc0000, 10%);
    }
}

.btn__holder {
    margin-top: 1rem;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-end;

    .button + .button,
    .btn + .btn {
        margin-left: 0.5rem;
    }
}
