main form {
    fieldset {
        @extend %reset;

        border: none;

        + fieldset { margin-top: 3rem; }
    }

    legend {
        @extend .h2;
    }

    input:not([type=checkbox]):not([type=radio]):not([type=submit]),
    textarea,
    select {
        appearance: none;
        width: 100%;
        padding: $width-gap / 2;
        border-radius: $width-radius;
        background: #fff;
        vertical-align: top;
        font-size: inherit;
        font-family: inherit;
        -webkit-font-smoothing: antialiased;
    }

    input[type=submit], button {
        @extend .button.secondary;
        @extend .button;

        appearance: none;
        border: none;
        padding: 0.75rem;
    }

    .checkbox,
    .radio {
        input { margin-right: 0.375rem; }
    }

    label {
        display: block;
        width: 100%;
        margin-bottom: 0.25rem;
        font-size: 0.875rem;
    }

    .control-group + .control-group,
    .form-group + .form-group {
        margin-top: $width-gap;
    }

    .help-inline,
    .help-block {
        display: block;
        vertical-align: top;
        padding: $width-gap / 3;
        color: #fff;
        font-size: 0.875rem;
        margin-top: 0;
    }


    .errorlist {
        @extend %reset-list;

        margin-bottom: $width-gap;
        font-weight: bold;
    }

    .button__holder {
        display: flex;
        flex-flow: row nowrap;
        margin-top: $width-gap*1.5;

        button { margin-left: auto; }
    }

    // form splitting

    .form-halves {
        display: flex;
        flex-flow: row nowrap;

        > div {
            width: calc(50% - (#{$width-gap} / 4));
            flex-shrink: 0;
        }
        > div:first-of-type { margin-right: ($width-gap / 2); }

        .control-group + .control-group,
        .form-group + .form-group {
            margin-top: 0;
        }

        + .form-halves,
        + .form-thirds,
        + .form-fourths,
        + .control-group,
        + .form-group {
            margin-top: $width-gap;
        }

        @media all and (max-width: 875px) {
            > div:first-of-type { margin-right: 0; }
        }
    }

    .form-thirds {
        display: flex;
        flex-flow: row nowrap;

        > div {
            width: calc((100% / 3) - (#{$width-gap} / 3));
            flex-shrink: 0;
        }
        > div:nth-last-of-type(n+2) { margin-right: ($width-gap / 2); }

        .control-group ~ .control-group,
        .form-group ~ .form-group {
            margin-top: 0;
        }

        + .form-thirds,
        + .form-halves,
        + .form-fourths,
        + .control-group,
        + .form-group {
            margin-top: $width-gap;
        }

        @media all and (max-width: 875px) {
            > div:nth-last-of-type(n+2) { margin-right: 0; }
        }
    }

    .form-fourths {
        display: flex;
        flex-flow: row nowrap;

        > div {
            width: calc((100% / 4) - (#{$width-gap*1.5} / 4));
            flex-shrink: 0;
        }
        > div:nth-last-of-type(n+2) { margin-right: ($width-gap / 2); }

        .control-group ~ .control-group,
        .form-group ~ .form-group {
            margin-top: 0;
        }

        + .form-thirds,
        + .form-halves,
        + .form-fourths,
        + .control-group,
        + .form-group {
            margin-top: $width-gap;
        }

        @media all and (max-width: 875px) {
            > div:nth-last-of-type(n+2) { margin-right: 0; }
        }
    }

    .form-halves,
    .form-thirds,
    .form-fourths {
        @media all and (max-width: 875px) {
            flex-flow: row wrap;

            > div { width: 100%; }

            .control-group ~ .control-group,
            .form-group ~ .form-group {
                margin-top: $width-gap;
            }
        }
    }
}

.control-label {
    font-size: 1.25em
}

.controls {
    overflow: hidden;
}

label.checkbox, label.radio {
    float: left;
    margin-right: 1em;
    margin-bottom: 2em;
    width: auto;
    img {
        display: block;
    }
}

.dynamic-form {
    margin-top: 3rem;
    border: 3px solid #efefef;
    padding: 2em;
}

.delete-row,
.add-row {
    margin-top: 1rem;
    display: inline-block;
    padding: 0.5em;
    background: green;
    color: white;
    text-decoration: none;
    border-bottom: 2px solid darken(green, 10%)
}

.delete-row {
    background: red;
    border-bottom: 2px solid darken(red, 10%);
}
