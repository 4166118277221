.page-logo {
    max-width: 13rem;
    max-height: 6rem;
    width: auto;
    height: auto;

    .navbar & {
    	margin: 1rem 2rem 1rem 1rem;
    	padding: 1rem 0 1rem 0;
    }
}

.logo {
    margin: 1rem 0;
}

.pathways-logo {
    width: 17.5rem;
    height: 5rem;
    background: url(../img/pathways_logo.png) center center/contain no-repeat;
    flex-shrink: 10;
    margin: 1rem 1.5rem 0.5rem 0;
}
