body.lightbox-open {
    overflow: hidden;
}

#lightbox {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0; right: 0; bottom: 0; left: 0;
    align-items: center;
    justify-content: center;
    animation: fade_in 0.5s ease-out;

    &.lightbox_visible {
        display: flex;
    }

    &.lightbox_fadeout {
        animation: fade_out 0.5s ease-in;
    }

    #lightbox_backdrop {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -10;
        background: rgba(#000, 0.95);
        cursor: pointer;
    }

    #lightbox_close {
        position: absolute;
        top: 1rem; right: 1rem;
        font-size: 2rem;
        color: #fff;
        text-decoration: none;
        user-select: none;
        pointer-events: none;
    }
}

#lightbox_content p {
    padding: 1.5rem;
    background: #fff;
    color: $color-text;
}

@keyframes fade_out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fade_in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes up_in {
    from {
        opacity: 0;
        transform: translateY(-2rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
