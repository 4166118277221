//fonts
@font-face {
    font-family: 'Cabin';
    src: url(../fonts/cabin-regular-webfont.woff);
    font-weight: normal;
}
@font-face {
    font-family: 'Cabin';
    src: url(../fonts/cabin-italic-webfont.woff);
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Cabin';
    src: url(../fonts/cabin-bold-webfont.woff);
    font-weight: bold;
}

@font-face {
    font-family: 'Cabin';
    src: url(../fonts/cabin-bolditalic-webfont.woff);
    font-weight: bold;
    font-style: italic;
}

// Base sizes
$base-font-size: 1rem !default;
$base-line-height: 1.5 !default;
$base-font: 'Cabin', Arial;


// Colors
$color-text: #4A4A4A !default;
$color-header-text: #4A4A4A !default;
$color-background: lighten(#E4E4E4, 5%) !default;
$color-header-background: lighten(#E4E4E4, 20%) !default;
$color-footer: lighten(#E4E4E4, 9.25%) !default;

$color-inverted-background: black !default;
$color-inverted-text: #fff !default;

$color-primary: lighten(#E4E4E4, 5%) !default;
$color-secondary: #8B63A1 !default;
$color-accent: #E4E4E4 !default;
$color-line: #B2AAA0 !default;
$color-error: #CC0000 !default;

// Themes
/////// GOLD
$color-gold-text: #3d3d3d !default;
$color-gold-header-text: #FFFFFF !default;
$color-gold-background: #333132 !default;
$color-gold-header-background: #FFFFFF !default;
$color-gold-footer: lighten(#B3995D, 45%) !default;
$color-gold-inverted-background: black !default;
$color-gold-inverted-text: #fff !default;
$color-gold-primary: #B3995D !default;
$color-gold-secondary: #7F6531 !default;
$color-gold-accent: #B3995D !default;
$color-gold-line: #F5F3F2 !default;

//////// GREY
$color-gold-text: #3d3d3d !default;
$color-gold-header-text: #FFFFFF !default;

//////// BLUE
$color-blue: #012d6a !default;


// Size
$width-gap: 1.5rem;
$width-line: 0.125rem;
$width-container: 80rem !default;
$width-column-main: 60%;
$width-column-side: 40%;
$width-column-gap: 2rem;
$width-radius: 0.125rem;


// Headlines

///     FONT SIZE  |  LINE H  |  MARGIN T  |  MARGIN B
$headline-scale: (
    h1: (3.375rem,    1.1,       2,           1),
    h2: (2.25rem,     1.2,       2,           1),
    h3: (1.5rem,      1.5,       2,           1),
    h4: (1.25rem,     1.1,       1.5,         1),
    h5: (1.1rem,      1.1,       1.25,        1),
    h6: (1rem,        1,         .5,          0)
) !default;


// Icon

$icon-width: 1.5rem;
$icon-height: $icon-width;
