@import 'normalize/import-now';

@import 'variables';
@import 'typography';
@import 'accessibility';

@import 'mixins/mixins';

@import 'layout/layout';
@import 'elements/elements';
@import 'forms/forms';

@import 'theme';

* {
    box-sizing: border-box;
}
