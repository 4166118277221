body.default {
	@include theme(
		$color-text,
		$color-header-text,
		$color-background,
		$color-header-background,
		$color-footer,
		$color-inverted-background,
		$color-inverted-text,
		$color-primary,
		$color-secondary,
		$color-accent,
		$color-line
	);
}

body.gold {
	@include theme(
		$color-gold-text,
		$color-gold-header-text,
		$color-gold-background,
		$color-gold-header-background,
		$color-gold-footer,
		$color-gold-inverted-background,
		$color-gold-inverted-text,
		$color-gold-primary,
		$color-gold-secondary,
		$color-gold-accent,
		$color-gold-line
	);
}

body.blue {
    @include theme(
        $color-text,
        $color-header-text,
        $color-background,
        $color-header-background,
        $color-footer,
        $color-inverted-background,
        $color-inverted-text,
        $color-primary,
        $color-blue,
        $color-accent,
        $color-line
    );

    .page-header {
        background: $color-blue;
        color: #fff;
        border-color: lighten($color-blue, 10%);
    }

    .nav__primary {

        li + li { border-color: lighten($color-blue, 10%); }

        a:hover { background: lighten($color-blue, 5%); }
    }

    .nav__mobile {
        border-color: lighten($color-blue, 10%);

        li + li { border-color: lighten($color-blue, 10%); }

        a:hover {
            background: lighten($color-blue, 5%);
            color: #fff;
        }
    }
}

body.purple {
    @include theme(
        $color-text,
        $color-header-text,
        $color-background,
        $color-header-background,
        $color-footer,
        $color-inverted-background,
        $color-inverted-text,
        $color-primary,
        $color-secondary,
        $color-accent,
        $color-line
    );

    .page-header {
        background: $color-secondary;
        color: #fff;
        border-color: lighten($color-secondary, 20%);
    }

    .nav__primary {

        li + li { border-color: lighten($color-secondary, 20%); }

        a:hover { background: lighten($color-secondary, 10%); }
    }

    .nav__mobile {
        border-color: lighten($color-secondary, 20%);

        li + li { border-color: lighten($color-secondary, 20%); }

        a:hover {
            background: lighten($color-secondary, 10%);
            color: #fff;
        }
    }
}

body.grey {
    .main__header { border-color: $color-background; }

    .nav__mobile .cofe__bar {
        display: none;
    }
}

body.rainbow {
	@include theme(
		$color-text,
		$color-header-text,
		$color-background,
		$color-header-background,
		$color-footer,
		$color-inverted-background,
		$color-inverted-text,
		$color-primary,
		$color-secondary,
		$color-accent,
		$color-line
	);

	.page-header {
		border-bottom: none;
	}

	.nav__primary,
	.nav__mobile {
		li {
			flex-grow: 10;
			text-align: center;

			+ li {
				border-left: none;
				border-top: none;
			}

			&:nth-of-type(1) a {
				background: #8fc1b9;
				&:hover { background: lighten(#8fc1b9, 5%); }
			}
			&:nth-of-type(2) a {
				background: #a2d8dd;
				&:hover { background: lighten(#a2d8dd, 5%); }
			}
			&:nth-of-type(3) a {
				background: #b4dbf1;
				&:hover { background: lighten(#b4dbf1, 5%); }
			}
			&:nth-of-type(4) a {
				background: #bab6db;
				&:hover { background: lighten(#bab6db, 5%); }
			}
			&:nth-of-type(5) a {
				background: #c498c2;
				&:hover { background: lighten(#c498c2, 5%); }
			}
			&:nth-of-type(6) a {
				background: #d27ba3;
				&:hover { background: lighten(#d27ba3, 5%); }
			}
		}
	}
}


