body {
    font-family: $base-font, sans-serif;
    line-height: $base-line-height;
}

//// HEADLINES

// loop through heading size variable and assign sizes
@each $heading, $font-properties in $headline-scale {
  #{$heading}, .#{$heading} {
    font-size: nth($font-properties, 1);
    line-height: nth($font-properties, 2);
    margin-top: nth($font-properties, 3) * 1rem;
    margin-bottom: nth($font-properties, 4) * 1rem;
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight: normal;

    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
}

//// COMMON

a {
    color: inherit;
}

.text__center {
    text-align: center;
}
