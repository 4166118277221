.container {
    width: 100%;
    max-width: $width-container;
    margin: auto;
    padding: 0 $width-gap;

    &.pad__vertical {
        padding-top: 2rem;
    }
}
