.page-footer {
    padding: $width-gap*2 0;
    margin-top: $width-gap*2;

    .social {
        margin: 2rem 0 0 0 !important;
    }


    @media all and (min-width: 768px) {
        .social {
            justify-content: flex-end;
            margin: 0 0 0 auto !important;

            a {
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.page-footer__legal {
    font-size: 0.875rem;

    .icon {
        width: 1em;
        height: 1em;
        vertical-align: middle;
        margin-right: 0.5em;
    }
}

.page-footer__contact {
    margin-top: 1.5rem;

    p { margin: 0.25rem 0; }
}

.footer__badges {
    display: flex;
    flex-flow: row nowrap;
    margin: 1rem 0 0 -1rem;
    align-items: center;

    .badge {
        max-width: 7rem;
        max-height: 4.5rem;
        margin: 1rem;
        filter: grayscale(100%);
        width: auto;
        height: auto;

        + .badge {
            margin-left: 1rem;
        }
    }

    @media all and (min-width: 768px) {
        justify-content: flex-end;

        margin: 1rem -1rem 0 0;
    }
}
