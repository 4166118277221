%reset {
    margin: 0;
    padding: 0;
}

%reset-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
