/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

@font-face {
  font-family: 'Cabin';
  src: url(../fonts/cabin-regular-webfont.woff);
  font-weight: normal; }

@font-face {
  font-family: 'Cabin';
  src: url(../fonts/cabin-italic-webfont.woff);
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Cabin';
  src: url(../fonts/cabin-bold-webfont.woff);
  font-weight: bold; }

@font-face {
  font-family: 'Cabin';
  src: url(../fonts/cabin-bolditalic-webfont.woff);
  font-weight: bold;
  font-style: italic; }

body {
  font-family: "Cabin", Arial, sans-serif;
  line-height: 24px; }

h1, .h1 {
  font-size: 3.375rem;
  line-height: 1.1;
  margin-top: 2rem;
  margin-bottom: 1rem; }

h2, .h2, main form legend {
  font-size: 2.25rem;
  line-height: 1.2;
  margin-top: 2rem;
  margin-bottom: 1rem; }

h3, .h3 {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-top: 2rem;
  margin-bottom: 1rem; }

h4, .h4 {
  font-size: 1.25rem;
  line-height: 1.1;
  margin-top: 1.5rem;
  margin-bottom: 1rem; }

h5, .h5 {
  font-size: 1.1rem;
  line-height: 1.1;
  margin-top: 1.25rem;
  margin-bottom: 1rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1;
  margin-top: 0.5rem;
  margin-bottom: 0rem; }

h1, .h1,
h2, .h2, main form legend,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: normal; }
  h1:first-child, .h1:first-child,
  h2:first-child, .h2:first-child, main form legend:first-child,
  h3:first-child, .h3:first-child,
  h4:first-child, .h4:first-child,
  h5:first-child, .h5:first-child,
  h6:first-child, .h6:first-child {
    margin-top: 0; }
  h1:last-child, .h1:last-child,
  h2:last-child, .h2:last-child, main form legend:last-child,
  h3:last-child, .h3:last-child,
  h4:last-child, .h4:last-child,
  h5:last-child, .h5:last-child,
  h6:last-child, .h6:last-child {
    margin-bottom: 0; }

a {
  color: inherit; }

.text__center {
  text-align: center; }

.sr__text,
.sr__input,
.sr__label {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

main form fieldset {
  margin: 0;
  padding: 0; }

.nav__primary .nav__sidebar ul,
.nav__mobile ul, .social, main form .errorlist {
  list-style: none;
  margin: 0;
  padding: 0; }

.hidden {
  display: none; }

.container {
  width: 100%;
  max-width: 80rem;
  margin: auto;
  padding: 0 1.5rem; }
  .container.pad__vertical {
    padding-top: 2rem; }

.nav {
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }

.admin__buttons {
  margin-left: auto; }

.nav__primary .nav__sidebar a,
.nav__mobile a {
  text-decoration: none; }

.nav__primary {
  display: none; }
  @media all and (min-width: 768px) {
    .nav__primary {
      display: block;
      width: 100%; }
      .nav__primary ul {
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        list-style: none; }
      .nav__primary a {
        display: block;
        text-decoration: none;
        padding: 1rem; } }

.nav__brand {
  max-width: 8rem;
  max-height: 8rem; }
  .nav__brand img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%; }

.nav__toggle {
  padding: 1rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: auto;
  cursor: pointer; }
  .nav__toggle .icon {
    vertical-align: middle;
    margin-left: 0.5em; }
  @media all and (min-width: 768px) {
    .nav__toggle {
      display: none; } }

.nav__close {
  display: none; }

.nav__sidebar li + li {
  margin: 1.5rem 0 0 0; }

.nav__mobile {
  display: none; }
  .nav__mobile ul {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%; }
  .nav__mobile li {
    width: 100%;
    text-align: center; }
  .nav__mobile a {
    display: block;
    width: 100%;
    padding: 1rem; }
  @media all and (min-width: 768px) {
    .nav__mobile {
      display: none; } }

@media all and (max-width: 768px) {
  #nav_toggle:checked ~ .nav .nav__toggle .nav__open {
    display: none; }
  #nav_toggle:checked ~ .nav .nav__toggle .nav__close {
    display: inline-block; }
  #nav_toggle:checked ~ .nav__mobile {
    display: block; } }

.cofe__bar {
  display: none;
  flex-flow: row nowrap;
  justify-content: flex-end;
  color: #fff;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  text-transform: uppercase; }
  .cofe__bar a {
    display: block;
    padding: 0.375rem 0.75rem;
    text-decoration: none; }
    .cofe__bar a:hover {
      background: #fff; }
  .nav__mobile .cofe__bar {
    display: flex;
    flex-flow: column nowrap;
    font-size: 1rem;
    text-align: center; }
    .nav__mobile .cofe__bar a {
      padding: 1rem; }
    .nav__mobile .cofe__bar a + a {
      border-left: none; }
  @media all and (min-width: 768px) {
    .cofe__bar {
      display: flex; } }

.page-header__buttons {
  margin-left: auto; }

.microsite__form {
  margin: 2rem 0; }
  .microsite__form img {
    width: auto; }
  .microsite__form .help-block,
  .microsite__form .help-inline {
    background: #B2AAA0 !important; }
  .microsite__form .help-block[id*=error],
  .microsite__form .help-inline[id*=error] {
    background: #CC0000 !important;
    color: #fff; }
  .microsite__form .alert {
    color: #CC0000;
    margin-bottom: 1.5rem; }
    .microsite__form .alert a {
      font-weight: bold;
      margin-top: 1rem;
      display: block; }
      .microsite__form .alert a span {
        font-weight: normal; }
      .microsite__form .alert a:first-of-type {
        margin-top: 0; }
    .microsite__form .alert .errorlist {
      list-style: initial;
      padding-left: 2rem;
      font-weight: normal;
      color: inherit;
      font-style: italic;
      margin-bottom: 0; }
  .microsite__form .errorlist {
    background: #CC0000 !important;
    color: #fff !important;
    font-size: 0.875rem;
    padding: 0.5rem; }

.main-content {
  margin: 4.5rem 0; }

.main__header .container {
  position: relative;
  z-index: 10; }

.main__header.with-bg-img {
  position: relative; }
  .main__header.with-bg-img:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }

@media all and (max-width: 767px) {
  .main__header .navbar {
    flex-flow: column nowrap;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
    text-align: center; }
    .main__header .navbar .page-logo {
      margin: 0 0 0.25rem 0; } }

.panel {
  background: #f1f1f1;
  padding: 1rem; }
  .panel.secondary {
    background: #8B63A1;
    color: #fff; }

.row {
  display: flex;
  flex-flow: column nowrap; }
  @media all and (min-width: 768px) {
    .row {
      flex-flow: row nowrap;
      justify-content: center;
      width: calc(100% + 2rem);
      margin-left: -1rem; } }

.navbar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }
  .navbar h1 {
    margin-top: 0; }
  .navbar .page-logo {
    margin-right: 2rem; }

@media all and (min-width: 768px) {
  .column-1 {
    width: calc((1/12*100%) - 2rem); }
  .column-2 {
    width: calc((2/12*100%) - 2rem); }
  .column-3 {
    width: calc((3/12*100%) - 2rem); }
  .column-4 {
    width: calc((4/12*100%) - 2rem); }
  .column-5 {
    width: calc((5/12*100%) - 2rem); }
  .column-6 {
    width: calc((6/12*100%) - 2rem); }
  .column-7 {
    width: calc((7/12*100%) - 2rem); }
  .column-8 {
    width: calc((8/12*100%) - 2rem); }
  .column-9 {
    width: calc((9/12*100%) - 2rem); }
  .column-10 {
    width: calc((10/12*100%) - 2rem); }
  .column-11 {
    width: calc((11/12*100%) - 2rem); }
  .column-12 {
    width: calc((12/12*100%) - 2rem); }
  div[class*=column-] {
    margin: 0 1rem; } }

.main-videos .main-videos__video {
  width: 100%;
  max-width: 32.5rem;
  word-break: break-all;
  margin: auto; }
  .main-videos .main-videos__video .video {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
    margin-bottom: 1rem; }
  .main-videos .main-videos__video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .main-videos .main-videos__video + .main-videos__video {
    margin-top: 2.5rem; }

@media all and (min-width: 768px) {
  .main-videos .main-videos__video {
    margin: 0 1rem; }
    .main-videos .main-videos__video + .main-videos__video {
      margin-top: 0; } }

@media all and (max-width: 994px) {
  .main-content__holder {
    width: 100%; } }

div[class*=column-] > :first-child {
  margin-top: 0; }

.page__banner {
  width: 100%;
  height: auto; }

.page__banner__holder {
  display: none; }
  @media all and (min-width: 1280px) {
    .page__banner__holder {
      display: block; } }

.page-footer {
  padding: 3rem 0;
  margin-top: 3rem; }
  .page-footer .social {
    margin: 2rem 0 0 0 !important; }
  @media all and (min-width: 768px) {
    .page-footer .social {
      justify-content: flex-end;
      margin: 0 0 0 auto !important; }
      .page-footer .social a {
        opacity: 0.5; }
        .page-footer .social a:hover {
          opacity: 1; } }

.page-footer__legal {
  font-size: 0.875rem; }
  .page-footer__legal .icon {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    margin-right: 0.5em; }

.page-footer__contact {
  margin-top: 1.5rem; }
  .page-footer__contact p {
    margin: 0.25rem 0; }

.footer__badges {
  display: flex;
  flex-flow: row nowrap;
  margin: 1rem 0 0 -1rem;
  align-items: center; }
  .footer__badges .badge {
    max-width: 7rem;
    max-height: 4.5rem;
    margin: 1rem;
    filter: grayscale(100%);
    width: auto;
    height: auto; }
    .footer__badges .badge + .badge {
      margin-left: 1rem; }
  @media all and (min-width: 768px) {
    .footer__badges {
      justify-content: flex-end;
      margin: 1rem -1rem 0 0; } }

body.lightbox-open {
  overflow: hidden; }

#lightbox {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  animation: fade_in 0.5s ease-out; }
  #lightbox.lightbox_visible {
    display: flex; }
  #lightbox.lightbox_fadeout {
    animation: fade_out 0.5s ease-in; }
  #lightbox #lightbox_backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -10;
    background: rgba(0, 0, 0, 0.95);
    cursor: pointer; }
  #lightbox #lightbox_close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    color: #fff;
    text-decoration: none;
    user-select: none;
    pointer-events: none; }

#lightbox_content p {
  padding: 1.5rem;
  background: #fff;
  color: #4A4A4A; }

@keyframes fade_out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade_in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes up_in {
  from {
    opacity: 0;
    transform: translateY(-2rem); }
  to {
    opacity: 1;
    transform: translateY(0); } }

.icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor; }

.social {
  display: flex;
  flex-flow: row nowrap; }
  .social > li + li {
    margin-left: 1.5rem; }

.button, main form input[type=submit], main form button,
.btn {
  display: inline-block;
  padding: 0.75rem;
  user-select: none;
  cursor: pointer; }
  .button.primary, main form input.primary[type=submit], main form button.primary,
  .btn.primary {
    color: #fff; }
  .button.secondary, main form input[type=submit], main form button,
  .btn.secondary {
    color: #fff; }
  .button.error, main form input.error[type=submit], main form button.error,
  .btn.error {
    background: #cc0000;
    color: #fff;
    border-bottom: 2px solid #990000; }

.btn__holder {
  margin-top: 1rem;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end; }
  .btn__holder .button + .button, .btn__holder main form input[type=submit] + .button, main form .btn__holder input[type=submit] + .button, .btn__holder main form button + .button, main form .btn__holder button + .button, .btn__holder main form .button + input[type=submit], main form .btn__holder .button + input[type=submit], .btn__holder main form input[type=submit] + input[type=submit], main form .btn__holder input[type=submit] + input[type=submit], .btn__holder main form button + input[type=submit], main form .btn__holder button + input[type=submit], .btn__holder main form .button + button, main form .btn__holder .button + button, .btn__holder main form input[type=submit] + button, main form .btn__holder input[type=submit] + button, .btn__holder main form button + button, main form .btn__holder button + button,
  .btn__holder .btn + .btn {
    margin-left: 0.5rem; }

.page-logo {
  max-width: 13rem;
  max-height: 6rem;
  width: auto;
  height: auto; }
  .navbar .page-logo {
    margin: 1rem 2rem 1rem 1rem;
    padding: 1rem 0 1rem 0; }

.logo {
  margin: 1rem 0; }

.pathways-logo {
  width: 17.5rem;
  height: 5rem;
  background: url(../img/pathways_logo.png) center center/contain no-repeat;
  flex-shrink: 10;
  margin: 1rem 1.5rem 0.5rem 0; }

img {
  width: 100%;
  height: auto;
  vertical-align: top; }

main form fieldset {
  border: none; }
  main form fieldset + fieldset {
    margin-top: 3rem; }

main form input:not([type=checkbox]):not([type=radio]):not([type=submit]),
main form textarea,
main form select {
  appearance: none;
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.125rem;
  background: #fff;
  vertical-align: top;
  font-size: inherit;
  font-family: inherit;
  -webkit-font-smoothing: antialiased; }

main form input[type=submit], main form button {
  appearance: none;
  border: none;
  padding: 0.75rem; }

main form .checkbox input,
main form .radio input {
  margin-right: 0.375rem; }

main form label {
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
  font-size: 0.875rem; }

main form .control-group + .control-group,
main form .form-group + .form-group {
  margin-top: 1.5rem; }

main form .help-inline,
main form .help-block {
  display: block;
  vertical-align: top;
  padding: 0.5rem;
  color: #fff;
  font-size: 0.875rem;
  margin-top: 0; }

main form .errorlist {
  margin-bottom: 1.5rem;
  font-weight: bold; }

main form .button__holder {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 2.25rem; }
  main form .button__holder button {
    margin-left: auto; }

main form .form-halves {
  display: flex;
  flex-flow: row nowrap; }
  main form .form-halves > div {
    width: calc(50% - (1.5rem / 4));
    flex-shrink: 0; }
  main form .form-halves > div:first-of-type {
    margin-right: 0.75rem; }
  main form .form-halves .control-group + .control-group,
  main form .form-halves .form-group + .form-group {
    margin-top: 0; }
  main form .form-halves + .form-halves,
  main form .form-halves + .form-thirds,
  main form .form-halves + .form-fourths,
  main form .form-halves + .control-group,
  main form .form-halves + .form-group {
    margin-top: 1.5rem; }
  @media all and (max-width: 875px) {
    main form .form-halves > div:first-of-type {
      margin-right: 0; } }

main form .form-thirds {
  display: flex;
  flex-flow: row nowrap; }
  main form .form-thirds > div {
    width: calc((100% / 3) - (1.5rem / 3));
    flex-shrink: 0; }
  main form .form-thirds > div:nth-last-of-type(n+2) {
    margin-right: 0.75rem; }
  main form .form-thirds .control-group ~ .control-group,
  main form .form-thirds .form-group ~ .form-group {
    margin-top: 0; }
  main form .form-thirds + .form-thirds,
  main form .form-thirds + .form-halves,
  main form .form-thirds + .form-fourths,
  main form .form-thirds + .control-group,
  main form .form-thirds + .form-group {
    margin-top: 1.5rem; }
  @media all and (max-width: 875px) {
    main form .form-thirds > div:nth-last-of-type(n+2) {
      margin-right: 0; } }

main form .form-fourths {
  display: flex;
  flex-flow: row nowrap; }
  main form .form-fourths > div {
    width: calc((100% / 4) - (2.25rem / 4));
    flex-shrink: 0; }
  main form .form-fourths > div:nth-last-of-type(n+2) {
    margin-right: 0.75rem; }
  main form .form-fourths .control-group ~ .control-group,
  main form .form-fourths .form-group ~ .form-group {
    margin-top: 0; }
  main form .form-fourths + .form-thirds,
  main form .form-fourths + .form-halves,
  main form .form-fourths + .form-fourths,
  main form .form-fourths + .control-group,
  main form .form-fourths + .form-group {
    margin-top: 1.5rem; }
  @media all and (max-width: 875px) {
    main form .form-fourths > div:nth-last-of-type(n+2) {
      margin-right: 0; } }

@media all and (max-width: 875px) {
  main form .form-halves,
  main form .form-thirds,
  main form .form-fourths {
    flex-flow: row wrap; }
    main form .form-halves > div,
    main form .form-thirds > div,
    main form .form-fourths > div {
      width: 100%; }
    main form .form-halves .control-group ~ .control-group,
    main form .form-halves .form-group ~ .form-group,
    main form .form-thirds .control-group ~ .control-group,
    main form .form-thirds .form-group ~ .form-group,
    main form .form-fourths .control-group ~ .control-group,
    main form .form-fourths .form-group ~ .form-group {
      margin-top: 1.5rem; } }

.control-label {
  font-size: 1.25em; }

.controls {
  overflow: hidden; }

label.checkbox, label.radio {
  float: left;
  margin-right: 1em;
  margin-bottom: 2em;
  width: auto; }
  label.checkbox img, label.radio img {
    display: block; }

.dynamic-form {
  margin-top: 3rem;
  border: 3px solid #efefef;
  padding: 2em; }

.delete-row,
.add-row {
  margin-top: 1rem;
  display: inline-block;
  padding: 0.5em;
  background: green;
  color: white;
  text-decoration: none;
  border-bottom: 2px solid #004d00; }

.delete-row {
  background: red;
  border-bottom: 2px solid #cc0000; }

body.default {
  color: #4A4A4A; }
  body.default .inverted {
    background: black;
    color: #fff; }
  body.default .page-header {
    color: #4A4A4A;
    background: #f1f1f1;
    border-bottom: 0.375rem solid #E4E4E4;
    box-shadow: 0px 0.5rem 1rem -0.75rem #E4E4E4; }
  body.default .nav__primary a:hover {
    background: #e4e4e4; }
  body.default .nav__primary li + li {
    border-left: 0.125rem solid #E4E4E4; }
  body.default .nav__mobile {
    border-top: 0.125rem solid #E4E4E4; }
    body.default .nav__mobile li + li {
      border-top: 0.125rem solid #e4e4e4; }
    body.default .nav__mobile a:hover {
      background: #e4e4e4; }
  body.default .cofe__bar {
    background: #8B63A1;
    color: #fff; }
    body.default .cofe__bar a + a {
      border-left: 1px solid #baa3c7; }
    body.default .cofe__bar a:hover {
      background: #fff;
      color: #4A4A4A; }
    .nav__mobile body.default .cofe__bar {
      border-top: 0.25rem solid #7e5892; }
      .nav__mobile body.default .cofe__bar a + a {
        border-top: 1px solid #baa3c7; }
  body.default .page-footer {
    background: #fcfcfc;
    color: #4A4A4A;
    border-bottom: 0.75rem solid whitesmoke;
    box-shadow: 0px 100vh 0px 100vh whitesmoke; }
  body.default .main__header {
    background: white; }
    body.default .main__header.with-bg-img:after {
      background: rgba(255, 255, 255, 0.925); }
    @media all and (max-width: 767px) {
      body.default .main__header {
        border-top: 0.5rem solid #8B63A1; } }
  body.default .btn.primary {
    background: #f1f1f1;
    border-bottom: 2px solid #d7d7d7; }
  body.default .btn.secondary {
    background: #8B63A1;
    border-bottom: 2px solid #704f82; }
  body.default main form input:not([type=checkbox]):not([type=radio]):not([type=submit]),
  body.default main form textarea,
  body.default main form select {
    border: 0.125rem solid #B2AAA0; }
  body.default main form .errorlist {
    color: #CC0000;
    margin-bottom: 0; }
    body.default main form .errorlist + .field-error {
      margin-top: 0; }
  body.default main form .field-error label {
    color: #CC0000; }
  body.default main form .field-error input:not([type=checkbox]):not([type=radio]):not([type=submit]),
  body.default main form .field-error textarea,
  body.default main form .field-error select {
    border-color: #CC0000; }

body.gold {
  color: #3d3d3d; }
  body.gold .inverted {
    background: black;
    color: #fff; }
  body.gold .page-header {
    color: #FFFFFF;
    background: #333132;
    border-bottom: 0.375rem solid #B3995D;
    box-shadow: 0px 0.5rem 1rem -0.75rem #B3995D; }
  body.gold .nav__primary a:hover {
    background: #262525; }
  body.gold .nav__primary li + li {
    border-left: 0.125rem solid #B3995D; }
  body.gold .nav__mobile {
    border-top: 0.125rem solid #B3995D; }
    body.gold .nav__mobile li + li {
      border-top: 0.125rem solid #a88d4f; }
    body.gold .nav__mobile a:hover {
      background: #a88d4f; }
  body.gold .cofe__bar {
    background: #7F6531;
    color: #fff; }
    body.gold .cofe__bar a + a {
      border-left: 1px solid #be9c58; }
    body.gold .cofe__bar a:hover {
      background: #fff;
      color: #3d3d3d; }
    .nav__mobile body.gold .cofe__bar {
      border-top: 0.25rem solid #6d562a; }
      .nav__mobile body.gold .cofe__bar a + a {
        border-top: 1px solid #be9c58; }
  body.gold .page-footer {
    background: #fcfbf9;
    color: #3d3d3d;
    border-bottom: 0.75rem solid #f8f6f1;
    box-shadow: 0px 100vh 0px 100vh #f8f6f1; }
  body.gold .main__header {
    background: #FFFFFF; }
    body.gold .main__header.with-bg-img:after {
      background: rgba(255, 255, 255, 0.925); }
    @media all and (max-width: 767px) {
      body.gold .main__header {
        border-top: 0.5rem solid #7F6531; } }
  body.gold .btn.primary {
    background: #B3995D;
    border-bottom: 2px solid #967e47; }
  body.gold .btn.secondary {
    background: #7F6531;
    border-bottom: 2px solid #5a4823; }
  body.gold main form input:not([type=checkbox]):not([type=radio]):not([type=submit]),
  body.gold main form textarea,
  body.gold main form select {
    border: 0.125rem solid #F5F3F2; }
  body.gold main form .errorlist {
    color: #CC0000;
    margin-bottom: 0; }
    body.gold main form .errorlist + .field-error {
      margin-top: 0; }
  body.gold main form .field-error label {
    color: #CC0000; }
  body.gold main form .field-error input:not([type=checkbox]):not([type=radio]):not([type=submit]),
  body.gold main form .field-error textarea,
  body.gold main form .field-error select {
    border-color: #CC0000; }

body.blue {
  color: #4A4A4A; }
  body.blue .inverted {
    background: black;
    color: #fff; }
  body.blue .page-header {
    color: #4A4A4A;
    background: #f1f1f1;
    border-bottom: 0.375rem solid #E4E4E4;
    box-shadow: 0px 0.5rem 1rem -0.75rem #E4E4E4; }
  body.blue .nav__primary a:hover {
    background: #e4e4e4; }
  body.blue .nav__primary li + li {
    border-left: 0.125rem solid #E4E4E4; }
  body.blue .nav__mobile {
    border-top: 0.125rem solid #E4E4E4; }
    body.blue .nav__mobile li + li {
      border-top: 0.125rem solid #e4e4e4; }
    body.blue .nav__mobile a:hover {
      background: #e4e4e4; }
  body.blue .cofe__bar {
    background: #012d6a;
    color: #fff; }
    body.blue .cofe__bar a + a {
      border-left: 1px solid #0258cf; }
    body.blue .cofe__bar a:hover {
      background: #fff;
      color: #4A4A4A; }
    .nav__mobile body.blue .cofe__bar {
      border-top: 0.25rem solid #012251; }
      .nav__mobile body.blue .cofe__bar a + a {
        border-top: 1px solid #0258cf; }
  body.blue .page-footer {
    background: #fcfcfc;
    color: #4A4A4A;
    border-bottom: 0.75rem solid whitesmoke;
    box-shadow: 0px 100vh 0px 100vh whitesmoke; }
  body.blue .main__header {
    background: white; }
    body.blue .main__header.with-bg-img:after {
      background: rgba(255, 255, 255, 0.925); }
    @media all and (max-width: 767px) {
      body.blue .main__header {
        border-top: 0.5rem solid #012d6a; } }
  body.blue .btn.primary {
    background: #f1f1f1;
    border-bottom: 2px solid #d7d7d7; }
  body.blue .btn.secondary {
    background: #012d6a;
    border-bottom: 2px solid #011837; }
  body.blue main form input:not([type=checkbox]):not([type=radio]):not([type=submit]),
  body.blue main form textarea,
  body.blue main form select {
    border: 0.125rem solid #B2AAA0; }
  body.blue main form .errorlist {
    color: #CC0000;
    margin-bottom: 0; }
    body.blue main form .errorlist + .field-error {
      margin-top: 0; }
  body.blue main form .field-error label {
    color: #CC0000; }
  body.blue main form .field-error input:not([type=checkbox]):not([type=radio]):not([type=submit]),
  body.blue main form .field-error textarea,
  body.blue main form .field-error select {
    border-color: #CC0000; }
  body.blue .page-header {
    background: #012d6a;
    color: #fff;
    border-color: #01429d; }
  body.blue .nav__primary li + li {
    border-color: #01429d; }
  body.blue .nav__primary a:hover {
    background: #013883; }
  body.blue .nav__mobile {
    border-color: #01429d; }
    body.blue .nav__mobile li + li {
      border-color: #01429d; }
    body.blue .nav__mobile a:hover {
      background: #013883;
      color: #fff; }

body.purple {
  color: #4A4A4A; }
  body.purple .inverted {
    background: black;
    color: #fff; }
  body.purple .page-header {
    color: #4A4A4A;
    background: #f1f1f1;
    border-bottom: 0.375rem solid #E4E4E4;
    box-shadow: 0px 0.5rem 1rem -0.75rem #E4E4E4; }
  body.purple .nav__primary a:hover {
    background: #e4e4e4; }
  body.purple .nav__primary li + li {
    border-left: 0.125rem solid #E4E4E4; }
  body.purple .nav__mobile {
    border-top: 0.125rem solid #E4E4E4; }
    body.purple .nav__mobile li + li {
      border-top: 0.125rem solid #e4e4e4; }
    body.purple .nav__mobile a:hover {
      background: #e4e4e4; }
  body.purple .cofe__bar {
    background: #8B63A1;
    color: #fff; }
    body.purple .cofe__bar a + a {
      border-left: 1px solid #baa3c7; }
    body.purple .cofe__bar a:hover {
      background: #fff;
      color: #4A4A4A; }
    .nav__mobile body.purple .cofe__bar {
      border-top: 0.25rem solid #7e5892; }
      .nav__mobile body.purple .cofe__bar a + a {
        border-top: 1px solid #baa3c7; }
  body.purple .page-footer {
    background: #fcfcfc;
    color: #4A4A4A;
    border-bottom: 0.75rem solid whitesmoke;
    box-shadow: 0px 100vh 0px 100vh whitesmoke; }
  body.purple .main__header {
    background: white; }
    body.purple .main__header.with-bg-img:after {
      background: rgba(255, 255, 255, 0.925); }
    @media all and (max-width: 767px) {
      body.purple .main__header {
        border-top: 0.5rem solid #8B63A1; } }
  body.purple .btn.primary {
    background: #f1f1f1;
    border-bottom: 2px solid #d7d7d7; }
  body.purple .btn.secondary {
    background: #8B63A1;
    border-bottom: 2px solid #704f82; }
  body.purple main form input:not([type=checkbox]):not([type=radio]):not([type=submit]),
  body.purple main form textarea,
  body.purple main form select {
    border: 0.125rem solid #B2AAA0; }
  body.purple main form .errorlist {
    color: #CC0000;
    margin-bottom: 0; }
    body.purple main form .errorlist + .field-error {
      margin-top: 0; }
  body.purple main form .field-error label {
    color: #CC0000; }
  body.purple main form .field-error input:not([type=checkbox]):not([type=radio]):not([type=submit]),
  body.purple main form .field-error textarea,
  body.purple main form .field-error select {
    border-color: #CC0000; }
  body.purple .page-header {
    background: #8B63A1;
    color: #fff;
    border-color: #baa3c7; }
  body.purple .nav__primary li + li {
    border-color: #baa3c7; }
  body.purple .nav__primary a:hover {
    background: #a383b4; }
  body.purple .nav__mobile {
    border-color: #baa3c7; }
    body.purple .nav__mobile li + li {
      border-color: #baa3c7; }
    body.purple .nav__mobile a:hover {
      background: #a383b4;
      color: #fff; }

body.grey .main__header {
  border-color: #f1f1f1; }

body.grey .nav__mobile .cofe__bar {
  display: none; }

body.rainbow {
  color: #4A4A4A; }
  body.rainbow .inverted {
    background: black;
    color: #fff; }
  body.rainbow .page-header {
    color: #4A4A4A;
    background: #f1f1f1;
    border-bottom: 0.375rem solid #E4E4E4;
    box-shadow: 0px 0.5rem 1rem -0.75rem #E4E4E4; }
  body.rainbow .nav__primary a:hover {
    background: #e4e4e4; }
  body.rainbow .nav__primary li + li {
    border-left: 0.125rem solid #E4E4E4; }
  body.rainbow .nav__mobile {
    border-top: 0.125rem solid #E4E4E4; }
    body.rainbow .nav__mobile li + li {
      border-top: 0.125rem solid #e4e4e4; }
    body.rainbow .nav__mobile a:hover {
      background: #e4e4e4; }
  body.rainbow .cofe__bar {
    background: #8B63A1;
    color: #fff; }
    body.rainbow .cofe__bar a + a {
      border-left: 1px solid #baa3c7; }
    body.rainbow .cofe__bar a:hover {
      background: #fff;
      color: #4A4A4A; }
    .nav__mobile body.rainbow .cofe__bar {
      border-top: 0.25rem solid #7e5892; }
      .nav__mobile body.rainbow .cofe__bar a + a {
        border-top: 1px solid #baa3c7; }
  body.rainbow .page-footer {
    background: #fcfcfc;
    color: #4A4A4A;
    border-bottom: 0.75rem solid whitesmoke;
    box-shadow: 0px 100vh 0px 100vh whitesmoke; }
  body.rainbow .main__header {
    background: white; }
    body.rainbow .main__header.with-bg-img:after {
      background: rgba(255, 255, 255, 0.925); }
    @media all and (max-width: 767px) {
      body.rainbow .main__header {
        border-top: 0.5rem solid #8B63A1; } }
  body.rainbow .btn.primary {
    background: #f1f1f1;
    border-bottom: 2px solid #d7d7d7; }
  body.rainbow .btn.secondary {
    background: #8B63A1;
    border-bottom: 2px solid #704f82; }
  body.rainbow main form input:not([type=checkbox]):not([type=radio]):not([type=submit]),
  body.rainbow main form textarea,
  body.rainbow main form select {
    border: 0.125rem solid #B2AAA0; }
  body.rainbow main form .errorlist {
    color: #CC0000;
    margin-bottom: 0; }
    body.rainbow main form .errorlist + .field-error {
      margin-top: 0; }
  body.rainbow main form .field-error label {
    color: #CC0000; }
  body.rainbow main form .field-error input:not([type=checkbox]):not([type=radio]):not([type=submit]),
  body.rainbow main form .field-error textarea,
  body.rainbow main form .field-error select {
    border-color: #CC0000; }
  body.rainbow .page-header {
    border-bottom: none; }
  body.rainbow .nav__primary li,
  body.rainbow .nav__mobile li {
    flex-grow: 10;
    text-align: center; }
    body.rainbow .nav__primary li + li,
    body.rainbow .nav__mobile li + li {
      border-left: none;
      border-top: none; }
    body.rainbow .nav__primary li:nth-of-type(1) a,
    body.rainbow .nav__mobile li:nth-of-type(1) a {
      background: #8fc1b9; }
      body.rainbow .nav__primary li:nth-of-type(1) a:hover,
      body.rainbow .nav__mobile li:nth-of-type(1) a:hover {
        background: #9fcac3; }
    body.rainbow .nav__primary li:nth-of-type(2) a,
    body.rainbow .nav__mobile li:nth-of-type(2) a {
      background: #a2d8dd; }
      body.rainbow .nav__primary li:nth-of-type(2) a:hover,
      body.rainbow .nav__mobile li:nth-of-type(2) a:hover {
        background: #b5e0e4; }
    body.rainbow .nav__primary li:nth-of-type(3) a,
    body.rainbow .nav__mobile li:nth-of-type(3) a {
      background: #b4dbf1; }
      body.rainbow .nav__primary li:nth-of-type(3) a:hover,
      body.rainbow .nav__mobile li:nth-of-type(3) a:hover {
        background: #c9e5f5; }
    body.rainbow .nav__primary li:nth-of-type(4) a,
    body.rainbow .nav__mobile li:nth-of-type(4) a {
      background: #bab6db; }
      body.rainbow .nav__primary li:nth-of-type(4) a:hover,
      body.rainbow .nav__mobile li:nth-of-type(4) a:hover {
        background: #cac7e3; }
    body.rainbow .nav__primary li:nth-of-type(5) a,
    body.rainbow .nav__mobile li:nth-of-type(5) a {
      background: #c498c2; }
      body.rainbow .nav__primary li:nth-of-type(5) a:hover,
      body.rainbow .nav__mobile li:nth-of-type(5) a:hover {
        background: #cda8cc; }
    body.rainbow .nav__primary li:nth-of-type(6) a,
    body.rainbow .nav__mobile li:nth-of-type(6) a {
      background: #d27ba3; }
      body.rainbow .nav__primary li:nth-of-type(6) a:hover,
      body.rainbow .nav__mobile li:nth-of-type(6) a:hover {
        background: #d88eb0; }

* {
  box-sizing: border-box; }
