.nav {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.admin__buttons {
    margin-left: auto;
}

.nav__primary
.nav__sidebar,
.nav__mobile {

    a { text-decoration: none; }

    ul {
        @extend %reset-list;
    }
}

.nav__primary {
    display: none;

    @media all and (min-width: 768px) {
        display: block;
        width: 100%;

        ul {
            width: 100%;
            padding: 0;
            margin: 0;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            list-style: none;
        }

        a {
            display: block;
            text-decoration: none;
            padding: 1rem;
        }
    }
}

.nav__brand {
    max-width: 8rem;
    max-height: 8rem;

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
}

.nav__toggle {
    padding: 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: auto;
    cursor: pointer;

    .icon {
        vertical-align: middle;
        margin-left: 0.5em;
    }

    @media all and (min-width: 768px) {
        display: none;
    }
}

.nav__close { display: none; }

.nav__sidebar {

    li + li { margin: $width-gap 0 0 0; }
}

.nav__mobile {
    display: none;

    ul {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 100%;
    }

    li {
        width: 100%;
        text-align: center;
    }

    a {
        display: block;
        width: 100%;
        padding: 1rem;
    }

    @media all and (min-width: 768px) {
        display: none;
    }
}

#nav_toggle:checked {
    @media all and (max-width: 768px) {
        ~ .nav .nav__toggle {

            .nav__open { display: none; }
            .nav__close { display: inline-block; }
        }

        ~ .nav__mobile {
            display: block;
        }
    }
}

.cofe__bar {
    display: none;
    flex-flow: row nowrap;
    justify-content: flex-end;
    color: #fff;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    a {
        display: block;
        padding: 0.375rem 0.75rem;
        text-decoration: none;

        &:hover { background: #fff; }
    }

    .nav__mobile & {
        display: flex;
        flex-flow: column nowrap;
        font-size: 1rem;
        text-align: center;

        a { padding: 1rem; }

        a + a {
            border-left: none;
        }
    }

    @media all and (min-width: 768px) {
        display: flex;
    }
}
