.microsite__form {
    margin: 2rem 0;
    img {
        width: auto;
    }

    .help-block,
    .help-inline {
        background: $color-line !important;
    }

    .help-block[id*=error],
    .help-inline[id*=error] {
        background: $color-error !important;
        color: #fff;
    }

    .alert {
        color: $color-error;
        margin-bottom: 1.5rem;

        a {
            font-weight: bold;
            margin-top: 1rem;
            display: block;

            span { font-weight: normal; }

            &:first-of-type { margin-top: 0; }
        }

        .errorlist {
            list-style: initial;
            padding-left: 2rem;
            font-weight: normal;
            color: inherit;
            font-style: italic;
            margin-bottom: 0;
        }
    }

    .errorlist {
        background: $color-error !important;
        color: #fff !important;
        font-size: 0.875rem;
        padding: 0.5rem;
    }
}

.main-content {
    margin: $width-gap*3 0;
}

.main__header {
    .container {
        position: relative;
        z-index: 10;
    }

    &.with-bg-img {
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: 1;
        }
    }


    @media all and (max-width: 767px) {
        .navbar {
            flex-flow: column nowrap;
            margin: 0.5rem 0.5rem 1rem 0.5rem;
            text-align: center;

            .page-logo {
                margin: 0 0 0.25rem 0;
            }
        }
    }
}

.panel {
    background: $color-primary;
    padding: 1rem;

    &.secondary {
        background: $color-secondary;
        color: #fff;
    }
}

.row {
    display: flex;
    flex-flow: column nowrap;

    @media all and (min-width: 768px) {
        flex-flow: row nowrap;
        justify-content: center;

        width: calc(100% + #{$width-column-gap});
        margin-left: -#{$width-column-gap/2};
    }
}

.navbar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    h1 { margin-top: 0; }

    .page-logo { margin-right: $width-column-gap; }
}

@media all and (min-width: 768px) {
    .column-1 { width: calc((1/12*100%) - #{$width-column-gap}) }
    .column-2 { width: calc((2/12*100%) - #{$width-column-gap}) }
    .column-3 { width: calc((3/12*100%) - #{$width-column-gap}) }
    .column-4 { width: calc((4/12*100%) - #{$width-column-gap}) }
    .column-5 { width: calc((5/12*100%) - #{$width-column-gap}) }
    .column-6 { width: calc((6/12*100%) - #{$width-column-gap}) }
    .column-7 { width: calc((7/12*100%) - #{$width-column-gap}) }
    .column-8 { width: calc((8/12*100%) - #{$width-column-gap}) }
    .column-9 { width: calc((9/12*100%) - #{$width-column-gap}) }
    .column-10 { width: calc((10/12*100%) - #{$width-column-gap}) }
    .column-11 { width: calc((11/12*100%) - #{$width-column-gap}) }
    .column-12 { width: calc((12/12*100%) - #{$width-column-gap}) }

    div[class*=column-] {
        margin: 0 $width-column-gap/2;
    }
}

.main-videos {

    .main-videos__video {
        width: 100%;
        max-width: 32.5rem;
        word-break: break-all;
        margin: auto;

        .video {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 0;
            margin-bottom: 1rem;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        + .main-videos__video {
            margin-top: 2.5rem;
        }
    }

    @media all and (min-width: 768px) {
        .main-videos__video {
            margin: 0 $width-column-gap/2;

            + .main-videos__video {
                margin-top: 0;
            }
        }
    }
}

@media all and (max-width: 994px) {
    .main-content__holder {
        width: 100%;
    }
}

div[class*=column-] > :first-child {
    margin-top: 0;
}

.page__banner {
    width: 100%;
    height: auto;
}

.page__banner__holder {
    display: none;

    @media all and (min-width: 1280px) {
        display: block;
    }
}
